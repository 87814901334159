<template>
  <div :class="[isEntityPage && 'disabled']" class="LessonEntityDetails__Component">
    <FieldInlineText label="שם המקבץ" :readOnly="isEntityPage" @onChange="onChange" v-model="model.name" />

    <div class="checkbox-container">
      <div>
        <b-switch style="width: 200px" :disabled="isEntityPage" class="" @input="onChange" v-model="model.displaySeperateLawText">{{
      model.displaySeperateLawText ? "דרופדאון" : "ללא דרופדאון"
    }}</b-switch>
      </div>
      <div>
        <b-switch style="width: 200px" :disabled="isEntityPage" class="" @input="onChange" v-model="model.isAcademy">{{
      model.isAcademy ? "אקדמיה" : "מתמחה"
    }}</b-switch>
      </div>
      <div>
        <b-switch style="width: 200px" class="" :disabled="isEntityPage" @input="onChange" v-model="model.isActive">{{
      model.isActive ? "פעיל" : "לא פעיל"
    }}</b-switch>
      </div>
    </div>

    <!-- <FieldInlineSelect label="בחר חוק מהרשימה" :readOnly="isEntityPage" :filterable="true" :clearable="true" :reduce="item => item"
      :optionsAsync="getLaws" @onChange="searchLawClauses" v-model="chosenLaw" />

    <div v-if="chosenLaw" class="new-law">
      <div class="item">
        <p>{{ chosenLaw.Text }}</p>
        <div style="cursor: pointer" @click="addVerdictItem()" class="add">
          <b-icon class="" pack="fa" icon="plus"></b-icon>
        </div>
      </div>
    </div>

    <div v-if="clauses.length" class="clauses">
      <div @click="addClauseToLaw(clause)" v-for="clause, idx in clauses" :class="[clause.isChecked ? 'clicked' : '']"
        :key="idx" class="clause">
        {{ clause.name }}
      </div>
    </div>

    <div class="laws-items">
      <div v-for="lawItem in model.laws" :key="lawItem.lawId">
        <div class="law-item">
          <p>{{ lawItem.lawName }}</p>
          <b-input type="number" @input="onChange" style="width: 70px" v-model="lawItem.sortOrder"></b-input>
          <div class="delete"></div>
        </div>
        <div class="clauses">
          <div @click="lawItem.isFullLaw = !lawItem.isFullLaw, onChange()" :class="[lawItem.isFullLaw && 'clicked']" class="clause">חוק מלא</div>
          <div @click="clause.isChecked = !clause.isChecked, onChange()" :class="{ 'clicked': clause.isChecked }" v-for="clause, idx in lawItem.lawClauses" :key="idx"
            class="clause">
            {{ clause.name }}
          </div>
        </div>
      </div>
    </div> -->

    <b-field v-if="model.laws && model.laws.length" style="margin-top: 15px;" label="חוקים מקושרים">
      <div class="laws">
        <div v-for="law in model.laws" :key="law.id" class="law">
          {{ law.lawName }}
        </div>
      </div>
    </b-field>
    <b-field v-if="model.videos && model.videos.length" style="margin-top: 15px;" label="סרטונים מקושרים">
      <div class="videos">
        <div v-for="video in model.videos" :key="video.id" class="video">
          {{ video.name }}
        </div>
      </div>
    </b-field>
    <b-field v-if="model.exercises && model.exercises.length" style="margin-top: 15px;" label="תרגולים מקושרים">
      <div class="exercises">
        <div v-for="exercise in model.exercises" :key="exercise.id" class="exercise">
          <span style="font-weight: bold;">{{ exercise.name }} </span>
          <span v-if="exercise.lessonName">מיחידת הלימוד <span style="font-weight: bold;">{{ exercise.lessonName }}
            </span></span>
          <span v-if="exercise.courseName">בקורס <span style="font-weight: bold;">{{ exercise.courseName }}
            </span></span>
        </div>
      </div>
    </b-field>

  </div>
</template>

<script>
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import { mapGetters, mapMutations } from 'vuex';
import CoursesService from '../services/CoursesService';
import AdminService from '../services/AdminService';

export default {
  name: 'LessonEntityDetails',
  components: {
    FieldInlineText,
    FieldInlineSelect,
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        id: null,
        name: null,
        displaySeperateLawText: null,
        exercises: null,
        videos: null,
        isAcademy: null,
        isActive: null,
        laws: null,
      },
      clauses: [],
      chosenLaw: null,
      clicked: []
    };
  },
  computed: {
    ...mapGetters('lawCollection', [
      'form',
    ]),
    courseId() {
      return this.$store.state.auth.course?.id;
    },
    isEntityPage() {
      return !!this.$route.params.id;
    },
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    addVerdictItem() {
      const currentLawClauses = this.clauses.filter((c) => c.isChecked).map((l) => ({
        id: l.id, dynamicId: l.dynamicId, isChecked: true, name: l.name
      }));

      this.model.laws.push({
        lawName: this.chosenLaw.Text,
        lawId: this.chosenLaw.Value,
        lawClauses: currentLawClauses
      });
      this.chosenLaw = null;
      this.clauses = [];
      this.$emit('onFormChange', this.changesAmount);
    },
    addClauseToLaw(clause) {
      clause.isChecked = !clause.isChecked;
    },
    getLaws() {
      return () =>
        AdminService.getLaws().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name, sortOrder: 1 }))
        );
    },
    searchLawClauses() {
      AdminService.getLawClauses(this.chosenLaw.Value)
        .then((r) => {
          this.clauses = r.data;
        });
    },
    getCategories() {
      return () =>
        CoursesService.getCategories().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    getLawCollections() {
      return () =>
        CoursesService.getLawCollections().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeLessonDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('lawCollection', [
      'storeLessonDetails',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 10px;
  margin-top: 10px;
}

.laws-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.law-item {
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    flex: 1;
    font-weight: bold;
  }
}

.clauses {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 400px;
  overflow: auto;
}

.clause {
  min-width: 40px;
  height: 30px;
  background: #fff;
  border: 1px solid #333;
  border-radius: 4px;
  color: #333;
  padding: 5px;
  margin: 5px 0 5px 10px;
  cursor: pointer;
}

.item {
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    flex: 1;
  }

  input {
    width: 50px;
  }
}

.disabled {
  pointer-events: none;
}

.clicked {
  background-color: gray;
}
</style>
